import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import DelayedLoadingMessage from './components/DelayedLoadingMessage/';
import { ReactQueryDevtools} from 'react-query-devtools'
import { QueryCache, ReactQueryCacheProvider} from 'react-query';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2E74C5', secondary: '#E89B49', tri: '#f5f5f5' },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
  },
});

const getCookie = (cookieKey) => {
  let cookieName = `${cookieKey}=`;
  let cookieArray = document.cookie.split(';');
  for (let cookie of cookieArray) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
};

const queryCache = new QueryCache();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice_name: null,
      impersonating: null,
      open_dialog_new_number: false,
      user_id: null,
      logged_out: document.cookie.indexOf('token=') >= 0 ? false : true,
      token: document.cookie.indexOf('token=') >= 0 ? getCookie('token') : '',
      onboarded: null,
      user_role: 'can_view',
      active_dashboard_cards: [],
      provider: [],
      country_login: '',
      api_version: '0.0',
      page_access: ['login', 'patients'],
      url_base: window.location.protocol + '//' + window.location.host.replace('app', 'api') + '/',
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.closeDialogs = this.closeDialogs.bind(this);
    this.renderRoute = this.renderRoute.bind(this);
  }

  componentDidMount() {
    const { url_base, token } = this.state;

    if (token === '' && window.location.pathname !== '/login' && window.location.pathname !== '/patients/onboarding') {
      window.location = window.location.protocol + '//' + window.location.host + '/login';
    }
    const apiURL = url_base + 'api/account_settings?token=' + token;
    return fetch(apiURL, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((responseJson) => {
        const obj = responseJson;
        if (obj) {
          this.setState({ country_login: obj.country ? obj.country.toLowerCase() : 'ca' });
          this.setState({ api_version: obj.system_version ? obj.system_version : '' });
          this.setState({ provider: obj.provider });
          if (obj.onboarding_new_number) {
            this.setState({ open_dialog_new_number: true });
          }

          //validate role
          var page_access = ['login', 'patients']; // assume patient
          if (obj.provider) {
            switch (obj.provider.role) {
              case 'developer':
                page_access = ['homepage', 'office_admin', 'portal', 'send', 'calendar', 'settings', 'dev_tools'];
                break;
              case 'provideradmin':
                page_access = ['homepage', 'office_admin', 'portal', 'send', 'settings'];
                break;
              case 'staff':
                page_access = ['homepage', 'portal', 'office_admin', 'send'];
                break;
              case 'provider':
                page_access = ['homepage', 'portal', 'send', 'settings'];
                break;
              default:
                page_access = ['login', 'patients'];
                break;
            }
          }
          this.setState({ page_access: page_access });
        } else {
          //logged out?
          console.error('logged out?');
          window.location = window.location.protocol + '//' + window.location.host + '/login';
        }
      })
      .catch((error) => {
        this.setState({ logged_out: true });
        console.error(error);
      });
  }
  login(response) {
    //now cookie to remember
    var days = 0.04;
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = '; expires=' + date.toGMTString();
    document.cookie = 'token=' + response.user.adracare_token + expires + '; path=/';
    this.setState({ logged_out: false, provider: response, token: response.user.adracare_token });
    window.location = window.location.protocol + '//' + window.location.host + '/';
  }
  logout() {
    //now cookie
    var days = 365;
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * -1000);
    var expires = '; expires=' + date.toGMTString();
    document.cookie = 'token=' + expires + '; path=/';
    this.setState({ logged_out: true, provider: [] });
    window.location = window.location.protocol + '//' + window.location.host + '/login';
  }
  closeDialogs() {
    this.setState({ open_dialog_new_number: false });
  }

  renderRoute(routerProps) {
    const { page_access, logged_out, provider } = this.state;
    const slug = routerProps.match.params.slug ? routerProps.match.params.slug : 'homepage';
    const sub_slug = routerProps.match.params.sub_slug;
    return page_access.includes(slug) ? (
      <>
        {(() => {
          switch (slug) {
            case 'office_admin':
              const AdminPage = React.lazy(() => import('./pages/Admin'));
              return <AdminPage {...this.state} logout={this.logout} />;
            case 'portal':
              const MDPortalPage = React.lazy(() => import('./pages/MDPortal/index'));
              return <MDPortalPage {...this.state} logout={this.logout} />;
            case 'send':
              const SendFaxPage = React.lazy(() => import('./pages/SendFax'));
              return <SendFaxPage {...this.state} logout={this.logout} />;
            case 'calendar':
              const MDCalendarPage = React.lazy(() => import('./pages/MDCalendar'));
              return <MDCalendarPage {...this.state} logout={this.logout} />;
            case 'settings':
              const AccountSettingsPage = React.lazy(() => import('./pages/AccountSettings'));
              return <AccountSettingsPage {...this.state} logout={this.logout} />;
            case 'dev_tools':
              const DevToolsPage = React.lazy(() => import('./pages/DevTools'));
              return <DevToolsPage {...this.state} logout={this.logout} />;
            case 'login':
              const LoginPage = React.lazy(() => import('./pages/Login'));
              return <LoginPage {...this.props} login={this.login} />;
            case 'patients':
              const PatientOnBoardingPage = React.lazy(() => import('./pages/PatientOnBoarding'));
              return <PatientOnBoardingPage />;
            case 'homepage':
            default:
              if (!logged_out) {
                if (provider.role === 'staff') {
                  const AdminPage = React.lazy(() => import('./pages/Admin'));
                  return <AdminPage {...this.state} logout={this.logout} />;
                } else {
                  const MDPortalPage = React.lazy(() => import('./pages/MDPortal/index'));
                  return <MDPortalPage {...this.state} logout={this.logout} />;
                }
              }
          }
        })()}
      </>
    ) : (
      () => {
        const LoginPage = React.lazy(() => import('./pages/Login'));
        return <LoginPage {...this.props} login={this.login} />;
      }
    );
  }
  render() {
    const { open_dialog_new_number, provider } = this.state;
    return (
      <ReactQueryCacheProvider queryCache={queryCache}>
      <MuiThemeProvider theme={theme}>
        <Router basename={process.env.PUBLIC_URL}>
          <React.Suspense fallback={<DelayedLoadingMessage />}>
            <Route exact path="/:slug?/:sub_slug?" render={this.renderRoute} />
          </React.Suspense>
        </Router>
        <Dialog
          open={open_dialog_new_number}
          onClose={this.closeDialogs}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
            {'Your New Fax Number:'}
          </DialogTitle>
          <Divider />
          <DialogContent style={{ width: 440, height: 300 }}>
            <div
              style={{
                margin: 0,
                borderSize: 0,
                borderColor: '#efefef',
                borderStyle: 'solid',
                padding: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <DialogContentText
                style={{ color: '#F40D58', margin: '60px auto 0px auto', fontSize: 36, textAlign: 'center' }}
              >
                {provider &&
                  provider.fax_number &&
                  provider.fax_number
                    .substr(2)
                    .replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function (_, p1, p2, p3, p4) {
                      let output = '';
                      if (p1) output = `${p1}`;
                      if (p2) output += `${p2}-`;
                      if (p3) output += `${p3}-`;
                      if (p4) output += `${p4}`;
                      return output;
                    })}
              </DialogContentText>
              <DialogContentText
                style={{ margin: '30px auto 0px auto', fontSize: 18, textAlign: 'center', padding: 5 }}
              >
                Faxes sent to this number will
                <br />
                show up under the <span style={{ color: '#F40D58' }}>Results</span> tab.
              </DialogContentText>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={this.closeDialogs} color="secondary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
      {/*<ReactQueryDevtools initialIsOpen />*/}
      </ReactQueryCacheProvider>
    );
  }
}

export default App;
